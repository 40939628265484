import Vue from 'vue';
import { InertiaApp, plugin } from '@inertiajs/inertia-vue';
import _ from 'lodash'
Vue.prototype._ = _

Vue.use(InertiaApp)

import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)

Vue.prototype.$route = route

import VueLuxon from "vue-luxon";
Vue.use(VueLuxon, {
    input: {
        // zone: "Europe/Rome",
        zone: "utc",
        format: "iso"
    },
    output: {
        zone: "local",
        format: "iso",
        lang: null,
    },
    templates: {
        client: {
            zone: "local",
            format: {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit"
            },
            relative: {
                round: true,
                unit: null
            },
        },
        client_date: {
            zone: "local",
            format: {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            },
            relative: {
                round: true,
                unit: null
            },
        },
    }
});

import 'vue-datetime/dist/vue-datetime.css';

import { DatePicker, TimePicker } from 'element-ui';
import lang from 'element-ui/lib/locale/lang/it';
import locale from 'element-ui/lib/locale';

// configure language
locale.use(lang);

Vue.use(DatePicker);
Vue.use(TimePicker);

import { InertiaProgress } from '@inertiajs/progress'
InertiaProgress.init()

import Highcharts from "highcharts";
import Stock from "highcharts/modules/stock";
import HighchartsVue from "highcharts-vue";

Stock(Highcharts);
Vue.use(HighchartsVue);

import JsonCSV from 'vue-json-csv'
Vue.component('downloadCsv', JsonCSV)

import VueTiger from "./tiger";
Vue.use(VueTiger)

const app = document.getElementById('app')

new Vue({
    render: h => h(InertiaApp, {
      props: {
        initialPage: JSON.parse(app.dataset.page),
        resolveComponent: name => import(`@/App/${name}`).then(module => module.default),
        resolveErrors: page => (page.props.errors || {}),
      },
    }),
  }).$mount(app)
