import queryString from "query-string";

function install (Vue) {

    let euro = Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
    })

    const methods = {
        callResultLabel: function (result) {
            return _.upperFirst(result)
                .replace(/([a-z](?=[A-Z]))/g, '$1 ')
                .replace(/[wW][aA](?=\s)/g, 'WA')
        },
        scheduleTypeLabel: function (schedule) {
            let type = schedule.type
            if (schedule.type === 'chiamata') {
                if (_.has(schedule, 'data.call_type')) {
                    type += ' (' + _.get(schedule, 'data.call_type') + ')'
                }
            } else if (schedule.type === 'sessione') {
                if (_.has(schedule, 'data.session_type')) {
                    // type += ' (' + _.get(schedule, 'data.session_type') + ')'
                    type += ' (' + schedule.sessionTypeName + ')'
                }
            }
            return type
        },
        isEmailEquals: function (a, b) {
            return a.toLowerCase() === b.toLowerCase()
        },
        generateWhatsappUrl: function (phone, text) {
            return queryString.stringifyUrl({
                url: 'https://api.whatsapp.com/send/',
                query: {phone, text}
            })
        },
        currentUser() {
            return Vue.prototype.$page.props.current_user
        },
        isAdmin(user = null) {
            user = user || this.currentUser()
            return user.is_admin || user.role === 'admin';
        },
        isTutor(user = null) {
            user = user || this.currentUser()
            return user.role === 'tutor';
        },
        userCan: function (permission, user = null) {
            user = user || this.currentUser()
            return (user.can || []).includes(permission)
        },
        euro: function (price) {
            return euro.format(price)
        },
        featureFlag: function (key) {
            return _.get(Vue.prototype.$page.props.feature_flags, `${key}.enabled`, false)
        }
    }

    Vue.tiger = methods

    Vue.prototype.$tiger = methods

}

export default install
